@hideTime: 0.7s;

.root {
  position: relative;
}
.hidden {
  height: 0 !important;
}
:global {
  .ant-layout-header {
    &:local(.header) {
      color: white;
      background: @primary-color;
      padding: 0 25px;
      font-size: 20px;
      display: flex;
      align-items: center;
      overflow: hidden;
      transition: @hideTime height;
    }

    .ant-typography {
      font-size: 24px;
      margin-bottom: 0;
      color: white;
    }
  }
}

.menuButton {
  margin-right: 25px;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.heightToggle {
  color: @primary-color;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  padding: 2px 1ch;
  bottom: 0;
  left: 50%;
  transform: translateY(100%);
  background: white;
  border: thin dashed @primary-color;
  border-top: thin solid white;
  cursor: pointer;
  border-radius: 0 0 5px 5px;

  .root:not(:hover) & {
    opacity: 0;
    //pointer-events: none;
  }

  &,
  * {
    transition: @hideTime;
  }
}

@primary-color: #1884E5;