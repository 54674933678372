.diffContainer {
  font-size: 16px;
  padding: 20px;
  min-height: 3.5em;
  max-height: 500px;
  overflow-y: scroll;
  border: thin dashed lightgray;
  & > * {
    margin-bottom: 0;
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.btn:global(.ant-btn) {
  display: block;
  margin: 15px auto;
}

.noChanges {
  text-align: center;
  margin-top: 5px;
}

@primary-color: #1884E5;