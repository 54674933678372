.root {
  display: flex;
  align-items: center;
  //width: 100vw;
}

.scrollContainer {
  height: 40px;
  overflow-x: scroll;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    display: none;
  }
}

.addSheetIcon:global(.anticon) {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 1.5em;
  cursor: pointer;
}

:global {
  :local(.sheets).ant-radio-group-solid {
    display: flex;
    height: 100%;

    .ant-radio-button-wrapper {
      padding-right: 7px;
      & span:not(.ant-radio-button) {
        font-weight: 500;
      }

      &:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: none;
      }
    }
  }
}

.plusIconWrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

@primary-color: #1884E5;