.root {
  padding: 3em 15vw;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 25px;
}

.title {
  font-size: 24px;
}

.listBody {
  background: #f1f1f1;
}

.row {
  padding: 0 !important;
  background: white;
  font-size: 20px;
  transition: 0.3s box-shadow;
  margin-bottom: 2px;

  &:hover {
    box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
  }

  &:last-child {
    margin-bottom: 0;
  }

  :global {
    .ant-list-item-action {
      margin: 0;
      margin-right: 5px;
      padding: 0 12px;
      border-left: 1px solid #ededed;

      li {
        padding: 0;
      }
      .anticon {
        padding: 10px;
        font-size: 18px;
      }
    }
  }
}

.link {
  display: block;
  padding: 20px 30px;
  flex-grow: 1;
}

.rowData {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowAuthorData {
  color: darkgrey;
  font-size: 12px;
}

@primary-color: #1884E5;