.header {
  display: flex;
  justify-content: space-between;
  &Element {
    margin: 15px 20px 10px;
    width: 300px;
  }
}

.row {
  margin-bottom: 7px;
}

@primary-color: #1884E5;