.name {
  align-items: baseline;
  :global {
    .ant-form-item-label > label {
      font-size: 16px;
    }
  }
}

.nameForm {
  display: flex;
  align-items: baseline;
  gap: 15px;
}

@primary-color: #1884E5;