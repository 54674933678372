.appSider {
  :global {
    .ant-layout-sider-children {
      background: white;
    }
    .ant-menu-item {
      font-size: 16px;
      .anticon {
        font-size: 16px;
      }
    }
  }
}

@primary-color: #1884E5;