.indicatorsContainer {
  position: absolute;
  top: 8px;
  right: 8px;

  display: flex;
  gap: 5px;

  font-size: 1em;
}

@primary-color: #1884E5;