.root {
  display: flex;
  gap: 50px;
  flex-direction: column;
  align-items: center;
}

.file:global(.ant-upload) {
  padding: 40px;
  min-width: 350px;

  & > span:global(.ant-upload) {
    font-size: 1.5em;
  }

  .icon {
    font-size: 1.5em;
    display: block;
    margin-bottom: 20px;
  }
}

.fileWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn:global(.ant-btn) {
  display: block;
  margin: 15px auto;
}

@primary-color: #1884E5;