.root {
  height: 100%;
  background: #fcfcfc;
}

.header {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 15px;
  background: #eff2f5;
  border-bottom: thin solid #babfc7;

  .arrow {
    padding: 15px;
    font-size: 18px;
  }

  .title {
    font-size: 24px;
    margin-bottom: 0;
  }
}

.buttonsContainer {
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 15vh auto 10vh;
  gap: 10vh;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  padding: 40px 60px;
  border: 4px dashed @primary-color;
  border-radius: 5px;

  cursor: pointer;
  background: rgba(0, 0, 0, 0);

  font-size: 30px;
  color: @primary-color;
  font-weight: 600;

  transition: 0.5s;
  &:hover {
    color: darken(@primary-color, 10%);
    border-color: darken(@primary-color, 10%);
    background: rgba(0, 0, 0, 0.05);
  }
}

@primary-color: #1884E5;