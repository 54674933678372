.root {
  min-height: 100vh;
  padding: 15vh 20px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.textWrapper {
  composes: wrapper;
  margin-bottom: 30px;
  .title,
  .text {
    color: @primary-color;
    font-weight: bold;
  }
  .title {
    font-size: 40px;
  }
  .text {
    font-size: 20px;
  }
}

.widgetsWrapper {
  composes: wrapper;
  padding: 30px;
}

@primary-color: #1884E5;