.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.root {
  opacity: 1;
}

.tab {
  display: inline-flex;
  align-items: center;
  gap: 3px;
  &.active .icon:global(.anticon) {
    color: white;
  }
}

.icon:global(.anticon) {
  padding: 1px 3px;
  color: #1890ff;
  transform: translateY(1px);
}

.customMenu {
  background: white;
  position: absolute;
  top: -100%;
  left: 0;
  display: none;
}

@primary-color: #1884E5;