@dividerColor: rgba(0, 0, 0, 0.15);

.root {
  border-bottom: thin dashed @dividerColor;

  .heading {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  .headDivider:global(.ant-divider) {
    margin: 0;
    display: inline-flex;
    align-items: flex-start;
    border-color: @dividerColor;
  }

  :global(.ant-divider) {
    border-width: thin;
    border-style: dashed none none;
    border-color: @dividerColor;
  }
}

.form {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 10fr 1fr 10fr;
  justify-items: center;
  margin-top: 10px;

  :global(.ant-typography) {
    text-align: center;
    margin: 10px 0;
  }
}

.fieldsWrapper {
  width: 85%;
  display: flex;
  flex-direction: column;
  column-gap: 50px;
  margin-top: 20px;
}

.divider {
  width: 1px;
  transform: translateY(-0.7em);
  border-right: thin dashed rgba(0, 0, 0, 0.1);
  height: calc(100% + 0.7em);
}

@primary-color: #1884E5;