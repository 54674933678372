.table {
  :global {
    .ag-status-bar {
      width: 100vw;
      padding-inline: 0 !important;
      background: #f0f2f5;

      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(0, auto) auto;
      gap: 25px;

      &-center {
        display: none;
      }
      &-right {
        justify-content: flex-end;
      }
    }
    .ag-react-container {
      width: 100%;
    }

    .ag-status-panel-aggregations {
      align-items: center;
      .ag-status-name-value {
        padding: 0;
      }
    }

    .ag-cell {
      padding: 0 !important;
      .ag-cell-inline-editing {
        transform: translateY(-1px);
      }
      &:not(.ag-cell-inline-editing) {
        padding: 0 11px;
      }
    }
  }

  .centerValue {
    text-align: center;
  }

  .boolMark {
    font-size: 16px;
    font-weight: 500;
  }
}

@primary-color: #1884E5;