.container {
  position: relative;
  height: 100%;
}

.indicator {
  z-index: 100000;
  position: absolute;
  top: 2px;
  left: 3px;
  color: #ff4d4f;
  font-size: 16px;
  line-height: 1;
}

@primary-color: #1884E5;