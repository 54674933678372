.root {
  padding: 0 20px;
}

.heading {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  flex-wrap: wrap;
  gap: 20px;

  .selectLabel {
    font-size: 16px;
    margin-right: 10px;
    margin-top: 20px;
  }
}

.btn:global(.ant-btn) {
  display: block;
  margin: 15px auto;
}

@primary-color: #1884E5;