.diffContainer {
  padding: 0 20px 10px;
  height: 500px;
  overflow-y: scroll;
  border: thin dashed lightgray;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.btn:global(.ant-btn) {
  display: block;
  margin: 15px auto;
}

@primary-color: #1884E5;