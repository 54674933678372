:global {
  :local(.form).ant-form {
    flex-direction: column;
    .ant-form-item {
      flex-direction: column;
      align-items: flex-start;
      margin-right: 5px;
      width: 18ch;

      &-control {
        width: 100%;
        &-input-content {
          display: flex;
          justify-content: center;
        }
      }

      &-with-help {
        margin-bottom: 0;
      }

      &-label > label {
        height: 2em;
        font-weight: 500;
      }
    }
  }
}

.formItemWrapper {
  position: relative;
}

.rowContainer {
  display: flex;
  gap: 5px;
}
.addBtn:global(.ant-btn) {
  margin-left: 2.1em;
  background: transparent;
  &:active,
  &:focus,
  &:hover {
    background: transparent;
  }
  margin-top: 10px;
}

.actionBtn {
  margin: 35px 0.5ch 0;
  font-size: 18px;
  transition: 0.3s color;
  &:hover {
    color: @primary-color;
  }
  &.inactive {
    margin: 0 15px;
    * {
      display: none !important;
    }
  }
}

@primary-color: #1884E5;