.input:not(.any) {
  width: 100%;
  height: 100%;
  border: none;

  &.number {
    width: 100%;

    :global {
      .ant-input-number-input-wrap {
        height: 100%;

        input {
          height: 100%;
        }
      }
    }
  }

  &.select {
    display: block;

    :global {
      .ant-select-selector {
        height: 100% !important;

        .ant-select-selection-item {
          color: currentColor;
          line-height: min(var(--ag-row-height, 40px), 40px);
        }
      }
    }
  }

  &.boolean {
    font-size: 1em;
    :global(.ant-select-selection-item) {
      font-weight: 500;
      height: 100% !important;
    }
  }
}

.booleanDropdown {
  :global(.ant-select-item-option-content) {
    text-align: center;
    font-weight: 500;
  }
}

@primary-color: #1884E5;