.spinner {
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: transparent !important;
  padding: 1em !important;
  position: unset !important;
}

@primary-color: #1884E5;