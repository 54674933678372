.input {
  pointer-events: none;
}

.dropdown {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  :global(.anticon) {
    font-size: 12px;
    color: lighten(black, 30%);
    transition: 0.3s;
    padding: 2px;
    border-radius: 2px;
    transform: translateX(2px);
  }
  &:hover :global(.anticon) {
    background: rgba(0, 0, 0, 0.075);
  }
}

@primary-color: #1884E5;