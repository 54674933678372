.input {
  width: 100%;

  &.number {
    width: 100%;
  }

  &.select {
    :global(.ant-select-selection-item) {
      color: currentColor;
    }
  }

  &.boolean {
    font-size: 1em;

    :global(.ant-select-selection-item) {
      font-weight: 500;
      text-align: center;
    }
  }
}

.booleanDropdown {
  :global(.ant-select-item-option-content) {
    text-align: center;
    font-weight: 500;
  }
}

@primary-color: #1884E5;