.meta {
  &Container {
    position: relative;
    height: 100%;
    padding: 0 11px;
  }

  &Dot {
    font-size: 12px;
    top: 5px;
    display: block;
    position: absolute;
    cursor: initial;
  }
}

.disabled {
  color: grey !important;
}

.error {
  color: #ff4d4f !important;
}

.errorIndicator {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #ff4d4f;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
}

.text {
  line-height: 0.9;
  font-size: 22px;
}

.disabledContainer {
  background-color: rgba(0, 0, 0, 0.03);
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.4);
}

@primary-color: #1884E5;