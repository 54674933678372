.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  color: #f56a00;
  background-color: #fde3cf;
  cursor: pointer;
}

@primary-color: #1884E5;