.root {
  max-width: 100vw;
  :global {
    .ant-collapse-header {
      background: rgb(240, 242, 245);
      flex-direction: row-reverse !important;
      align-items: center !important;
      padding-block: 0.5em !important;
      padding-left: 10px !important;
      padding-right: 20px !important;
    }
    .ant-collapse-extra {
      margin-left: unset !important;
      margin-right: auto;
    }
    .ant-collapse-content-box {
      background: #f8f8f8;
      padding: 10px 10px 15px !important;
      max-height: 200px;
      overflow: scroll;
    }
  }

  .extra {
    display: flex;
    align-items: center;
  }
}

.controlsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}

.leftGroup {
  float: left;
  height: 40px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.arrow {
  padding: 15px;
  font-size: 18px;
}

:global(.ant-typography).title {
  font-size: 24px;
  margin-bottom: 0.1em;
}
.controls {
  width: fit-content;
  margin-left: auto;
}
@transitionFunction: cubic-bezier(0.56, 0.04, 0.55, 0.99);
.formWrapper {
  overflow: scroll;
  transition: margin-top 0.7s @transitionFunction,
    max-height 1s @transitionFunction;
  max-height: 30vh;
  margin-top: 15px;

  &.closed {
    margin-top: 0;
    max-height: 0;
    animation: hideForm 0.7s forwards;
  }
}

@keyframes hideForm {
  from {
    display: initial;
  }
  to {
    display: none;
  }
}

@primary-color: #1884E5;