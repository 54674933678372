.root {
  padding: 0 14px;
}

.heading {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@primary-color: #1884E5;